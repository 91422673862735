import React, { Component } from "react";

export default class Resume extends Component {
  render() {
    return (
      <section id="resume">
        {/* Education
        ----------------------------------------------- */}
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Interests</span>
            </h1>
          </div>
          <img className="profile-pic" src="images/interests.png" alt="" />
          {/* main-col end */}
        </div>{" "}
        {/* End Education */}
        {/* Skills
        ----------------------------------------------- */}
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Technical Skills</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">
                <li>
                  <span className="bar-expand photoshop" />
                  <em>HTML5/CSS/React.js</em>
                </li>
                <li>
                  <span className="bar-expand illustrator" />
                  <em>MQTT - IoT Protocol</em>
                </li>
                <li>
                  <span className="bar-expand wordpress" />
                  <em>SQL</em>
                </li>
                <li>
                  <span className="bar-expand css" />
                  <em>Python</em>
                </li>
                <li>
                  <span className="bar-expand html5" />
                  <em>AWS Architecture</em>
                </li>
              </ul>
            </div>
            {/* end skill-bars */}
          </div>{" "}
          {/* main-col end */}
        </div>{" "}
        {/* End skills */}
      </section>
    );
  }
}
