import React, { Component } from "react";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Resume from "./components/Resume/Resume";
//import Portfolio from "./components/Portfolio/Portfolio";
//import Testimonials from  './components/Testimonials/Testimonials';
//import ContactUs from "./components//Contact/Contact";
import Footer from "./components/Footer/Footer";
import resumeData from "./components/Resume/resumeData";

//<Testimonials />
//<Portfolio />
//<ContactUs />

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header resumeData={resumeData} />
        <About />
        <Resume />
        <Footer />
      </div>
    );
  }
}
export default App;
