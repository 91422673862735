import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpg" alt="" />
          </div>
          <div className="nine columns main-col">
            <h2>Brief Bio</h2>
            <p>
              Rational Hippie, & Pragmatic Idealist. First generation American
              born and raised in the midwest. Worked the corporate 9-5 in big
              tech until taking a six month mini-retirement to 20 National Parks
              in 2021. Since then I've been a developer, consultant, founder,
              and nomad building and contributing to small-businesses and
              start-ups. Currently Angel Squad Member and Chicago Local Lead @{" "}
              <a href="https://www.hustlefund.vc/squad">Hustle Fund VC</a>,
              Technical Adviser @{" "}
              <a href="https://www.sangcash.com">SangCash</a>, and Esoteric
              Thinkboi @{" "}
              <a href="https://www.twitter.com/abusyhippie">Twitter</a>{" "}
            </p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Resume</h2>
              </div>
              <div className="columns download">
                <p>
                  <a href="images/resume.pdf" className="button">
                    <i className="fa fa-download" />
                    Download Resume
                  </a>
                </p>
              </div>
            </div>{" "}
            {/* end row */}
          </div>{" "}
          {/* end .main-col */}
        </div>
      </section>
    );
  }
}
